import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SET_ROUTE } from "../Features/rightBarHeader";
import logo from "../assets/RightBar/Logo megnus 1.png";
import { RootState } from "..";
import {
  SET_DARK_MODE,
  SET_OPENING_CLOSING_LOG,
  SET_POS_CONFIG,
  SET_RESPONSE_ID,
  SET_SLUG,
  SET_TOKEN,
} from "../Features/leftBarHeader";
import axios from "axios";

import {
  INITIALIZE_TABLE_AREA,
  SET_BRANCH_TAX_PERCENTAGE,
  SET_LOGIN_DATA,
} from "../Features/cart";
import { log } from "console";
import {
  Root,
  Root2,
  MenuItemsList,
  MenuSizesList,
  FlavourAndToppingsList,
  OptionsList,
  RootNewMenu,
  ProductsCategory,
  Product,
  Size,
  Choice,
  Option,
} from "../Interfaces/IItemData"; // Replace with the correct path to your interfaces file.
import { createMenu } from "../Features/orderMenu";
import { BaseService, createNewBaseService } from "../assets/BaseService";
import { SET_CLOSING_DATE, SET_OPENING_DATE } from "../Features/salesReport";
import { useInternalMessage } from "antd/es/message/useMessage";
import { useReadLocalStorage } from "usehooks-ts";

const LoginPage = () => {
  const [userName, setUserName] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [isCodeLogin, setIsCodeLogin] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const [failedSignIn, setFailedSignIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = localStorage.getItem("access_token");
  const queryParams = new URLSearchParams(window.location.search);
  const business = queryParams.get("business");

  const pos_config = useSelector(
    (state: RootState) => state.leftHeader.pos_config
  );

  const NewBaseService = createNewBaseService(pos_config);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(SET_DARK_MODE((event.target as HTMLInputElement).value));
  };
  const darkMode = useSelector((state: RootState) => state.leftHeader.darkMode);
  const dispatch = useDispatch();
  let branchName: string = "";

  function convertJsonToFormat(jsonData: any): Record<string, string> {
    //const data: any = JSON.parse(jsonData).data;
    const data = jsonData.data;
    const result: Record<string, string> = {};

    data.forEach((config: any) => {
      config.keyValuePair.forEach(
        (pair: { key: string | number; value: string }) => {
          result[pair.key] = pair.value;
        }
      );
    });

    return result;
  }

  function getDataFromNewMenu(param: ProductsCategory[]) {
    let data: ProductsCategory[] = param;
    return data;
  }

  function convertDataFromFirstApiToSecond(
    apiData: ProductsCategory[]
  ): Root2[] {
    const convertedData: Root2[] = [];

    for (const category of apiData) {
      const convertedCategory: Root2 = {
        ID: category.ID || "",
        menuID: category.menuID,
        Name: category.Name || "",
        Description: category.Description || "",
        Order: category.ORDER,
        StartTime: category.startTime,
        EndTime: category.endTime,
        IsActive: category.IsActive,
        notForSale: false, //checking if it is not for sale
        ParentCategoryID: category.OutletID,
        MenuItemsList: convertProductsToMenuItemsList(
          category.products,
          category.ID
        ),
      };

      convertedData.push(convertedCategory);
    }

    return convertedData;
  }

  function convertProductsToMenuItemsList(
    products: Product[],
    categoryID: string
  ): MenuItemsList[] {
    const menuItemsList: MenuItemsList[] = [];

    for (const product of products) {
      const convertedProduct: MenuItemsList = {
        _id: "",
        ID: product.ID || "",
        Name: product.Name || "",
        RemoteCode: product.RemoteCode || "",
        Description: product.Description || "",
        ImageBase64:
          product.ItemImage || "",
        IsActive: product.IsActive || true, //checking for default
        ItemImage:
          product.ItemImage || "",
        StockNumber: 0,
        Cost: parseInt(product.size[0].Price),
        CategoryID: categoryID || "",
        IsDiscountApplicable: false,
        CategoryName: "", // Set this to an empty string as it's not provided in the first API data.
        MenuID: product.MenuID || "",
        MinDeliveryPrice: parseInt(product.size[0].Price),
        TakeawayPrice: parseInt(product.size[0].Price),
        DiscountedPrice: parseInt(product.size[0].Price),
        Discount: 0, // Initialize to 0 as it's not provided in the first API data.
        DiscountPercentage: 0, // Initialize to 0 as it's not provided in the first API data.
        DiscountPercentageTakeaway: 0, // Initialize to 0 as it's not provided in the first API data.
        MinimumDelivery: "", // Set this to an empty string as it's not provided in the first API data.
        MenuSizesList: convertSizesToMenuSizesList(product.size),
        SizeID: "",
        Serving: product.Serving,
        SpecialDeal: "", // Recieved Boolean from the API
        SpecialDealText: product.SpecialDealText,
        Timer: "", // Recieved Boolean from the API
        EndTime: product.ItemEndTime,
      };

      menuItemsList.push(convertedProduct);
    }

    return menuItemsList;
  }

  function convertSizesToMenuSizesList(sizes: Size[]): MenuSizesList[] | null {
    const menuSizesList: MenuSizesList[] = [];
    if (sizes[0].options.length === 0) {
      return null;
    }
    for (const size of sizes) {
      const convertedSize: MenuSizesList = {
        ID: parseInt(size.ID),
        MenuItemID: parseInt(size.MenuItemID),
        LinkedMenuSizeID: 0, // Initialize to 0 as it's not provided in the first API data.
        Size: size.Size || "",
        DineInPrice: parseInt(size.Price) || 0,
        ActualPrice: parseInt(size.Price) || 0,
        DeliveryPrice: parseInt(size.Price) || 0,
        TakeAwayPrice: parseInt(size.TakeawayPrice) || 0,
        MinDeliveryPrice: 0, // Initialize to 0 as it's not provided in the first API data.
        DiscountedPrice: parseInt(size.Price) || 0,
        Discount: 0, // Initialize to 0 as it's not provided in the first API data.
        FlavourAndToppingsList: convertOptionsToFlavourAndToppingsList(
          size.options
        ),
      };

      menuSizesList.push(convertedSize);
    }

    return menuSizesList;
  }

  function convertOptionsToFlavourAndToppingsList(
    options: Option[]
  ): FlavourAndToppingsList[] {
    const flavourAndToppingsList: FlavourAndToppingsList[] = [];

    for (const option of options) {
      const convertedOption: FlavourAndToppingsList = {
        ID: option.ID || "0",
        Name: option.Name || "",
        Price: parseInt(option.SpecialPrice),
        IsActive: true, //checking
        IsMultiple: option.MultiSelect,
        SortOrder: 0, // Initialize to 0 as it's not provided in the first API data.
        OptionsList: convertChoicesToOptionsList(option.choices),
      };

      flavourAndToppingsList.push(convertedOption);
    }

    return flavourAndToppingsList;
  }

  function convertChoicesToOptionsList(choices: Choice[]): OptionsList[] {
    const optionsList: OptionsList[] = [];

    for (const choice of choices) {
      const convertedChoice: OptionsList = {
        ID: parseInt(choice.ID) || 0,
        Name: choice.Name || "",
        Price: parseInt(choice.Price) || 0,
        OriginalPrice: parseInt(choice.OriginalPrice) || 0,
        Discount: 0, // Initialize to 0 as it's not provided in the first API data.
        IsActive: true, //checking
      };

      optionsList.push(convertedChoice);
    }

    return optionsList;
  }

  function stringToKebabCase(input: string): string {
    return input
      .replace(/([a-z])([A-Z])/g, "$1-$2") // Convert camelCase to kebab-case
      .replace(/\s+/g, "-") // Replace spaces with dashes
      .toLowerCase(); // Convert the string to lowercase
  }

  const handleOpenClosingLog = async (userData: any) => {
    await NewBaseService.post(
      "/dynamic/get",
      {
        collection: "openclosinglog",
        filter: {
          userId: userData.UserName,
        },
      },
      {}
    ).then(async (res) => {
      localStorage.setItem("userID", res.data.data.Id)
      if (res.data.data.length === 0) {
        const openingDate = new Date();
        dispatch(SET_OPENING_DATE(openingDate));
        dispatch(SET_OPENING_CLOSING_LOG(true));
        await NewBaseService.post(
          "/dynamic/create",
          {
            collection: "openclosinglog",
            data: {
              userId: userData.UserName,
              openingDate: openingDate.toISOString(),
              isClosed: "0",
            },
          },
          {}
        );
      } else {
        dispatch(SET_OPENING_CLOSING_LOG(false));
      }
    });
  };

  const handleEmployeeData = async (userData: any) => {
    // await NewBaseService.post("/dynamic/get", {
    //   collection: "employee",
    //   filter: {
    //     "user.label": userData.UserName,
    //   },
    // }).then((res) => {
    //   const branchName = res.data.data[0].branch[0].label || "";
    //   dispatch(
    //     SET_LOGIN_DATA({
    //       employeeID: res.data.data[0].employeeID || "",
    //       employeeName: res.data.data[0].employeeName || "",
    //       branchName: branchName,
    //       user: userData.userName || "",
    //     })
    //   );
    //   return branchName;
    // });

    const branchName = userData.branch;
    dispatch(
      SET_LOGIN_DATA({
        employeeID: userData.ID || "",
        employeeName: userData.firstName + " " + userData.lastName || "",
        branchName: branchName,
        user: userData.user || "",
      })
    );
    return branchName;
  };

  const handleBranchData = async (branchName: any) => {
    await NewBaseService.post(
      "/dynamic/get",
      {
        collection: "outlets",
        filter: {
          name: branchName,
        },
        // select: {
        //   menu: 1,
        //   taxPercentage: 1,
        // },
      },
      {}
    ).then((res) => {
      dispatch(SET_BRANCH_TAX_PERCENTAGE(res.data.data.tax || 5));
    }).catch((err) => {
      console.log("Menu get error", err);
    });
  };

  const handleMenuData = async (id: string) => {
    try {
      const [menuResponse] = await Promise.all([
        NewBaseService.get(`/dynamic/query/get-menu-by-id?menuId=${id}`),
        dispatch(INITIALIZE_TABLE_AREA(7))
      ]);

      const data = getDataFromNewMenu(menuResponse.data.data);
      const newData = convertDataFromFirstApiToSecond(data);
      dispatch(createMenu(newData));
    } catch (err) {
      console.log("Menu get error", err);
    }
  };


  const handlePostLoginOperations = async (userData: any) => {
    await handleOpenClosingLog(userData);
    const branchName = await handleEmployeeData(userData);
    await handleBranchData(branchName);
    await handleMenuData(userData.outlet);
  };

  const handleAutoLogin = async (username: string, security_key: string, token: string) => {
    setIsLoading(true);
    try {
      // Single API call to handle authentication and user data
      const verifyToken = await NewBaseService.post("/auth/poslogin", {
        userName: username,
        token,
        includeUserData: true // Add this flag to include all required data in single response
      });

      const verifiedData = verifyToken.data.data;

      // Set all required data in parallel
      await Promise.all([
        dispatch(SET_TOKEN(verifiedData.Token)),
        dispatch(SET_SLUG(stringToKebabCase(verifiedData.UserName))),
        dispatch(SET_RESPONSE_ID(verifiedData.ID)),
        handlePostLoginOperations(verifiedData)
      ]);

      // Set local storage items in parallel
      const storageItems = {
        'access_token': verifiedData.Token,
        'username': verifiedData.UserName,
        'Login Time': new Date().toLocaleString(),
        'name': verifiedData.user,
        'branch_id': verifiedData.outlet
      };

      Object.entries(storageItems).forEach(([key, value]) =>
        localStorage.setItem(key, value)
      );

      window.history.pushState({}, '', '/');
      dispatch(SET_ROUTE("/PosPage"));
    } catch (error) {
      console.error("Auto-login failed:", error);
      setFailedSignIn(true);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hashedData = urlParams.get('auth');
    if (hashedData) {
      const unhashedUrl = atob(hashedData);
      const urlObj = new URL(unhashedUrl);

      const hashedUsername = urlObj.searchParams.get('business');
      const hashedKey = urlObj.searchParams.get('key')
      const token = urlObj.searchParams.get('token')
      if (hashedKey && hashedUsername && token) {
        const username = atob(hashedUsername);
        const key = atob(hashedKey);
        if (username && key && token) {
          handleAutoLogin(username, key, token);
        }
      }
    }
  }, []);

  const handleClick = async () => {
    setIsLoading(true);
    let tokenGet = "";
    let menuId = "";
    var codeUserName: string;
    isCodeLogin
      ? await axios
        .post("https://api.megnus.app/dev/auth/signin/login-code", {
          business: business,
          loginCode: code,
        })
        .then(async (res) => {
          dispatch(SET_TOKEN(res.data.data.token));
          dispatch(SET_SLUG(stringToKebabCase(res.data.data.name)));
          dispatch(SET_RESPONSE_ID(res.data.data._id));
          codeUserName = res.data.data.userName;
          setUserName(res.data.data.userName);
          tokenGet = res.data.data.token;
          localStorage.setItem("access_token", tokenGet);
          localStorage.setItem("username", res.data.data.userName);
          const loginTime = new Date().toLocaleString();
          localStorage.setItem("Login Time", loginTime)
          localStorage.setItem("name", res.data.data.name);


          await BaseService.post(
            "/dynamic/get",
            {
              collection: "open-closing-log",
              filter: {
                createdBy: res.data.data._id,
                isClosed: false,
              },
            },
            {}
          ).then(async (res) => {
            if (res.data.data.length === 0) {
              const openingDate = new Date();
              dispatch(SET_OPENING_DATE(openingDate));
              dispatch(SET_OPENING_CLOSING_LOG(true));
              await BaseService.post(
                "/dynamic/create",
                {
                  collection: "open-closing-log",
                  data: {
                    userId: res.data.data._id,
                    openingDate: openingDate.toISOString(),
                    isClosed: false,
                  },
                },
                {}
              ).then((res) => {
                console.log(res.data.data);
              });
            } else {
              dispatch(SET_OPENING_CLOSING_LOG(false));
            }
          });

          await BaseService.post("/dynamic/get", {
            collection: "employee",
            filter: {
              "user.label": codeUserName,
            },
          }).then((res) => {
            branchName = res.data.data[0].branch[0].label || "";
            dispatch(
              SET_LOGIN_DATA({
                employeeID: res.data.data[0].employeeID || "",
                employeeName: res.data.data[0].employeeName || "",
                branchName: branchName,
                user: userName || "",
              })
            );
          });
        })
        .then(async () => {
          await BaseService.post(
            "/dynamic/get",
            {
              collection: "branch",
              filter: {
                branchName: branchName,
              },
              select: {
                menu: 1,
                taxPercentage: 1,
              },
            },
            {}
          )
            .then((res) => {
              dispatch(
                SET_BRANCH_TAX_PERCENTAGE(res.data.data[0].taxPercentage)
              );
            })
            .catch((err) => {
              console.log("Menu get error", err);
            });

          // await BaseService.post("/dynamic/get", { collection: "menu" }, {})
          //   .then((res) => {
          //     menuId = res.data.data[0]._id;
          //   })
          //   .catch((err) => {
          //     console.log("Menu get error", err);
          //   });
        })
        .then(async () => {
          await NewBaseService.get(
            `/dynamic/query/get-menu-by-id?menuId=5462`
          )
            .then((res) => {
              const data = getDataFromNewMenu(res.data.data);
              const newData = convertDataFromFirstApiToSecond(data);
              dispatch(createMenu(newData));
              dispatch(INITIALIZE_TABLE_AREA(7));
              dispatch(SET_ROUTE("/PosPage"));
            })
            .catch((err) => {
              console.log("Menu get error", err);
            });
        })
        .catch((err) => {
          console.log("Signin Code error", err);
          setFailedSignIn(true);
        })
      : await axios
        .post("https://bwapi.broadwaypizza.com.pk/dev/auth/loginbyusernamenpassword", {
          userName: userName,
          password: password,
        })
        .then(async (res) => {
          const userData = res.data.data;
          // dispatch(SET_TOKEN(userData.token));
          // dispatch(SET_SLUG(stringToKebabCase(userData.Name)));
          // dispatch(SET_RESPONSE_ID(userData.UserName));

          const storageItems = {
            'access_token': userData.token,
            'username': userData.UserName,
            'Login Time': new Date().toLocaleString(),
            'name': userData.user,
            'branch_id': userData.outlet
          };

          Object.entries(storageItems).forEach(([key, value]) =>
            localStorage.setItem(key, value)
          );

          await Promise.all([

            dispatch(SET_TOKEN(userData.token)),
            dispatch(SET_SLUG(stringToKebabCase(userData.UserName))),
            dispatch(SET_RESPONSE_ID(userData.ID)),
            handlePostLoginOperations(userData)
          ]);

          dispatch(SET_ROUTE("/PosPage"));
          // await NewBaseService.post(
          //   "/dynamic/get",
          //   {
          //     collection: "openclosinglog",
          //     filter: {
          //       userId: userData.UserName,
          //     },
          //   },
          //   {}
          // ).then(async (res) => {
          //   console.log(res.data.data);
          //   if (res.data.data.length === 0) {
          //     const openingDate = new Date();
          //     dispatch(SET_OPENING_DATE(openingDate));
          //     dispatch(SET_OPENING_CLOSING_LOG(true));
          //     await NewBaseService.post(
          //       "/dynamic/create",
          //       {
          //         collection: "open-closing-log",
          //         data: {
          //           userId: res.data.data._id,
          //           openingDate: openingDate.toISOString(),
          //           isClosed: false,
          //         },
          //       },
          //       {}
          //     ).then((res) => {
          //       console.log(res.data.data);
          //     });
          //   } else {
          //     dispatch(SET_OPENING_CLOSING_LOG(false));
          //   }
          // });

          // await BaseService.post("/dynamic/get", {
          //   collection: "employee",
          //   filter: {
          //     "user.label": userName,
          //   },
          // }).then((res) => {
          //   console.log("the branch is", res.data.data);
          //   branchName = res.data.data[0].branch[0].label || "";
          //   dispatch(
          //     SET_LOGIN_DATA({
          //       employeeID: res.data.data[0].employeeID || "",
          //       employeeName: res.data.data[0].employeeName || "",
          //       branchName: branchName,
          //       user: userName || "",
          //     })
          //   );
          // });
        })
        // .then(async () => {
        //   await BaseService.post(
        //     "/dynamic/get",
        //     {
        //       collection: "branch",
        //       filter: {
        //         branchName: branchName,
        //       },
        //       select: {
        //         menu: 1,
        //         taxPercentage: 1,
        //       },
        //     },
        //     {}
        //   )
        //     .then((res) => {
        //       console.log("data data", res.data.data);
        //       dispatch(
        //         SET_BRANCH_TAX_PERCENTAGE(res.data.data[0].taxPercentage)
        //       );
        //     })
        //     .catch((err) => {
        //       console.log("Menu get error", err);
        //     });

        //   // await BaseService.post("/dynamic/get", { collection: "menu" }, {})
        //   //   .then((res) => {
        //   //     menuId = res.data.data[0]._id;
        //   //   })
        //   //   .catch((err) => {
        //   //     console.log("Menu get error", err);
        //   //   });
        // })
        // .then(async () => {
        //   await NewBaseService.get(
        //     `/dynamic/query/get-menu-by-id?menuId=5462`,
        //   )
        //     .then((res) => {
        //       console.log("resdata", res.data.data);
        //       const data = getDataFromNewMenu(res.data.data);
        //       console.log("new data", data);
        //       const newData = convertDataFromFirstApiToSecond(data);
        //       dispatch(createMenu(newData));
        //       console.log("CONVERTED data", newData);
        //       
        //     })
        //     .catch((err) => {
        //       console.log("Menu get error", err);
        //     });
        // })
        .catch((err) => {
          console.log("Signin error", err);
          setFailedSignIn(true);
        });
    setIsLoading(false);
  };

  return (
    <div
      className={`${!darkMode ? "bg-[#645DBC]" : "bg-[#111827]"
        } h-screen flex items-start  justify-center select-none`}
    >
      {isLoading ? (
        <CircularProgress color="inherit" size={25} className="text-white text-[12px] mt-[60px]" />
      ) : (
        <>
          <div className="absolute right-0 top-[10px]">
            <div></div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={darkMode ? "Dark Mode" : "Light Mode"}
                onChange={handleChange}
              >
                <div className={`text-white flex`}>
                  <FormControlLabel
                    value="Light Mode"
                    control={<Radio />}
                    label="Light Mode"
                  />
                  <FormControlLabel
                    value="Dark Mode"
                    control={<Radio />}
                    label="Dark Mode"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="flex-cols gap-5 justify-center pt-[60px]">
            <div className="pb-[50px] flex justify-center">
              <img src={logo} alt="" />
            </div>
            <div className="p-[30px] bg-white rounded-[12px]">
              <div className="font-[700] text-[24px] flex justify-center py-[10px]">
                Login to Your Account
              </div>
              <div className={`${isCodeLogin && "hidden"} py-[10px]`}>
                <TextField
                  autoFocus
                  onChange={(e: any) => setUserName(e.target.value)}
                  className="w-[350px] bg-[#E8F0FE]"
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                />
              </div>
              <div className={`${isCodeLogin && "hidden"} py-[10px]`}>
                <TextField
                  onChange={(e: any) => setPassword(e.target.value)}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      handleClick();
                    }
                  }}
                  className="w-[350px] bg-[#E8F0FE]"
                  id="outlined-basic"
                  label="Password"
                  type="password"
                  variant="outlined"
                  placeholder="Enter 5 digit Pin"
                />
              </div>
              <div className={`${!isCodeLogin && "hidden"} py-[10px]`}>
                <TextField
                  autoFocus
                  onChange={(e: any) => setCode(e.target.value)}
                  className="w-[350px] bg-[#E8F0FE]"
                  id="outlined-basic"
                  label="Code"
                  variant="outlined"
                />
              </div>
              <div
                className={`${failedSignIn ? "text-[12px] text-red-600" : "hidden"
                  } `}
              >
                Username or Password is incorrect
              </div>
              <div className="py-[10px] flex justify-between items-center font-[600] text-[11px]">
                <div className="flex items-center justify-start text-[#111827]">
                  <div>
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => setChecked(!checked)}
                  >
                    Remember me
                  </div>
                </div>
                <div
                  className={`${!darkMode ? "text-[#645DBC]" : "text-black"
                    } cursor-pointer`}
                >
                  Forgot Password
                </div>
              </div>
              <button
                className={`py-[10px] h-[48px] ${!darkMode ? "bg-[#645DBC]" : "bg-black"
                  } w-full rounded-[6px] text-center cursor-pointer text-[#FFFFFF] font-[600] text-[16px] ${isLoading && "opacity-80 cursor-wait"
                  }`}
                disabled={isLoading}
                onClick={() => {
                  handleClick();
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={25}
                    className="text-white text-[12px]"
                  />
                ) : (
                  <h1>Sign In</h1>
                )}
              </button>
              <button
                className={`
            ${isCodeLogin && "hidden"}
            py-[10px] mt-[10px] h-[48px] ${!darkMode ? "bg-[#645DBC]" : "bg-black"
                  } w-full rounded-[6px] text-center cursor-pointer text-[#FFFFFF] font-[600] text-[16px] ${isLoading && "opacity-80 cursor-wait"
                  }`}
                disabled={isLoading}
                onClick={() => {
                  setIsCodeLogin(true);
                  setFailedSignIn(false);
                }}
              >
                <h1>Sign In Through Code</h1>
              </button>
              <button
                className={`
            ${!isCodeLogin && "hidden"}
            py-[10px] mt-[10px] h-[48px] ${!darkMode ? "bg-[#645DBC]" : "bg-black"
                  } w-full rounded-[6px] text-center cursor-pointer text-[#FFFFFF] font-[600] text-[16px] ${isLoading && "opacity-80 cursor-wait"
                  }`}
                disabled={isLoading}
                onClick={() => {
                  setIsCodeLogin(false);
                  setFailedSignIn(false);
                }}
              >
                <h1>Sign In Through Username</h1>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginPage;
